/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import { Helmet } from "react-helmet";

function SEO({ page, seo }) {
  return (
    <Helmet
      htmlAttributes={{
        lang: 'en'
      }}
      title={seo.title}
      titleTemplate={seo.title ? `Listener | ${seo.title}` : null}
      meta={getMetaTags(seo, page)}
    ></Helmet>
  )
}

const getMetaTags = (seo, page) => {
  const pageUrl = page ? `${process.env.GATSBY_WEBSITE_URL}/${page}/` : `${process.env.GATSBY_WEBSITE_URL}/`;

  const tags = [{
      name: `description`,
      content: seo.description,
    }, {
      name: `keywords`,
      content: seo.keywords,
    }, {
      property: `og:type`,
      content: `website`,
    }, {
      property: `og:url`,
      content: pageUrl
    }, {
      property: `twitter:url`,
      content: pageUrl
    },
    {
      name: `referrer`,
      content: `no-referrer-when-downgrade`,
    }
  ];

  if (seo.og_meta_tags) {
    tags.push(
      {
        property: `og:title`,
        content: seo.og_meta_tags.title,
      }, {
        property: `og:description`,
        content: seo.og_meta_tags.description,
      }, {
        property: `og:image`,
        content: (seo.og_meta_tags.image && seo.og_meta_tags.image.url),
      }
    );
  }

  if (seo.twitter_meta_tags) {
    tags.push(
      {
        property: `twitter:title`,
        content: seo.twitter_meta_tags.title,
      }, {
        property: `twitter:description`,
        content: seo.twitter_meta_tags.description,
      }, {
        property: `twitter:image`,
        content: (seo.twitter_meta_tags.image && seo.twitter_meta_tags.image.url),
      }
    );
  }

  return tags;
}

export default SEO;
