import React, { useEffect } from "react";

import Layout from "../components/common/layout/layout";
import SEO from "../components/common/seo";

import ReactMarkdown from "react-markdown";

const StaticPages = (context) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const staticPageConfigs = context.pageContext.node;
  const headerData = context.pageContext.headerConfigurations;
  const footerData = context.pageContext.footerConfigurations;

  if (!staticPageConfigs) return null;

  return (
    <Layout headerData={headerData} footerData={footerData}>
      { staticPageConfigs.seo && <SEO page={staticPageConfigs.slug} seo={staticPageConfigs.seo} /> }

      <section className="markdown-body">
        <h1 className="h1">{staticPageConfigs.heading}</h1>

        { staticPageConfigs.description && <p className="h2">{staticPageConfigs.description}</p> }

        <ReactMarkdown>
          {staticPageConfigs.page_description}
        </ReactMarkdown>
      </section>

    </Layout>
  )
}

export default StaticPages;
